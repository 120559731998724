<h2 mat-dialog-title>Scan QR Code</h2>

<mat-dialog-content class="qr-container">

  <canvas #qrCanvas *ngIf="qrCodeImage"></canvas>

  <img *ngIf="!qrCodeImage" [src]="qrCodeImage" alt="QR Code" />

  <p *ngIf="!qrCodeImage">Generating QR Code...</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
