import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'environments/environment';
import { AlertNotify } from './shared/types/alert-Notify';
import { LoginService } from './shared/services/login.service';
import { AlertService } from './shared/services/alert.service';
import { DOCUMENT } from '@angular/common';

declare const gtag: Function;
declare const webchat: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  alertNotifyList: AlertNotify[] = [];
  showDefaultHeader = true;
  isLogin = false;

  constructor(
    private router: Router,
    public toastr: ToastrService,
    private loginService: LoginService,
    private alertService: AlertService,
    vRef: ViewContainerRef
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.blockUI.start('Loading...');
      } else if (event instanceof RouteConfigLoadEnd) {
        this.blockUI.stop();
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-152080969-1', {
          page_path: event.urlAfterRedirects,
        });

        const customHeaderRoutes = ['order-summary'];
        const customHeaderValue = event.urlAfterRedirects.split('/')[1];
        this.showDefaultHeader = !customHeaderRoutes.includes(customHeaderValue);
      }
    });
  }

  async onRouterChange(e) {

    this.onHideTalkDeskChat();

    if (window.location.pathname == '/login') {
      this.isLogin = true;
      this.alertNotifyList = [];
    } else {
      this.isLogin = false;

      if (this.alertNotifyList.length === 0) {
        const token = this.loginService.getToken();
        await new Promise<void>((resolve, reject) => {
          this.alertService.getAlertsByUser(token.customernumber, token.userName).subscribe(
            (notifications: AlertNotify[]) => this.alertNotifyList = notifications,
            err => {
              console.error(err)
              reject();
            },
            () => resolve());
        });
      }
    }
  }

  onHideTalkDeskChat() {
    if (window.location.pathname != '/login') {
      if (webchat != undefined) {
        if (webchat.selfHostedApp != undefined) {
          webchat.selfHostedApp.close();
        }
      }
    }
  }
}
