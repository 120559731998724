<nav class="navbar sticky-top navbar-light bg-light header">
  <div class="container-fluid">
    <div class="row">
      <div class="col col-4 col-lg-5 col-md-4 align-self-center d-inline-flex">
        <a class="navbar-brand">
          <img (click)="openNewTab()" src="assets/icons/logo.png" alt="c¡nch" class="navbar-brand logo" />
        </a>
      </div>
      <div class="col col-6 align-self-center right" *ngIf="">
        <div class="row">
          <div class="col right">
            <span class="sign-in" (click)="signIn()">
              Sign In
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>