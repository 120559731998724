/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login-footer.component";
var styles_LoginFooterComponent = [i0.styles];
var RenderType_LoginFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginFooterComponent, data: {} });
export { RenderType_LoginFooterComponent as RenderType_LoginFooterComponent };
export function View_LoginFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12 col-md-6 text-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Qwikkit Logo"], ["class", "logo"], ["src", "assets/images/Qwikkit-Logo-wTag-Grey-White-RGB.png"], ["width", "230"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "col-12 col-md-6 text-end text-md-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "phone-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "phone-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\uE0B0"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "phone-texts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "phone-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "phone-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.phone; _ck(_v, 12, 0, currVal_0); var currVal_1 = _co.phone2; _ck(_v, 14, 0, currVal_1); }); }
export function View_LoginFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-footer", [], null, null, null, View_LoginFooterComponent_0, RenderType_LoginFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoginFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginFooterComponentNgFactory = i1.ɵccf("app-login-footer", i2.LoginFooterComponent, View_LoginFooterComponent_Host_0, {}, {}, []);
export { LoginFooterComponentNgFactory as LoginFooterComponentNgFactory };
