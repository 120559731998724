var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../environments/environment';
var ApartmentComplexInformationService = /** @class */ (function () {
    function ApartmentComplexInformationService(http) {
        this.http = http;
        this.savingApartment = new BehaviorSubject(true);
        this.sectionHandler = new BehaviorSubject(0);
        this.apartmentAddress = new BehaviorSubject({});
    }
    ApartmentComplexInformationService.prototype.getStates = function () {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/states", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.saveApartmentComplexInformation = function (data) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(environment.api + "api/complexes", data, { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getApartmentComplexInformationById = function (token, id) {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/complexes/" + id + "/update", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getApartmentShippingAddress = function (aptComplexID) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/complexes/" + aptComplexID + "/shippingaddress", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.saveNewLocation = function (data) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(environment.api + "api/complexes", data, { headers: headers });
    };
    ApartmentComplexInformationService.prototype.updateApartmentComplexInformation = function (apartmentComplexInfo) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.put(environment.api + "api/complexes", __assign({}, apartmentComplexInfo), { headers: headers });
    };
    ApartmentComplexInformationService.prototype.updateLocationInformation = function (apartmentComplexInfo) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.put(environment.api + "api/complexes/updatelocationinformation", __assign({}, apartmentComplexInfo), { headers: headers });
    };
    ApartmentComplexInformationService.prototype.updateShippingAddressLocation = function (apartmentComplexInfo) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.put(environment.api + "api/complexes/updateshippingaddresslocation", __assign({}, apartmentComplexInfo), { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getComplexShippingAddress = function (id) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/complexes/" + id + "/shippingaddress", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.updateComplexShippingAddress = function (shipping) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.put(environment.api + "api/orders/updateshippingaddress", shipping, { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getShippingAddressDetails = function (apartmentId) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/orders/" + apartmentId + "/shippingaddress", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getComplexShippingAddressDetails = function (apartmentComplexId) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/orders/" + apartmentComplexId + "/locationshippingaddress", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.updateCustomerpickUpAddress = function (apartmentID, value) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        // tslint:disable-next-line:prefer-const
        var searchParams = new Object();
        searchParams['apartmentID'] = apartmentID.toString();
        searchParams['value'] = value;
        var requestOptions = { headers: headers, params: searchParams };
        return this.http.put(environment.api + "api/complexes/customerpickup", '', requestOptions);
    };
    ApartmentComplexInformationService.prototype.getValidationImages = function () {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/complexes/validations/images", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getComplexAddress = function (id) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/complexes/" + id + "/addresses", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.validateCommunityName = function (complexId, complexName) {
        var headers = new HttpHeaders();
        // tslint:disable-next-line:prefer-const
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/complexes/validateapartmentcomplex", { headers: headers, params: { complexName: complexName, complexId: complexId } });
    };
    ApartmentComplexInformationService.prototype.getdefaulthingeoffset = function () {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/complexes/getdefaulthingeoffset", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getUserType = function () {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(environment.api + "api/account/iscustomerservice", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getCustomerContacts = function () {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/customercontact/getcustomercontacts", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getSalesRoles = function () {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/customercontact/getsalesroles", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getContacts = function () {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/contact/", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getCustomerContactsByCustomerContactId = function (contactid) {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/customercontact/" + contactid, { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getCustomerContactsByCustomerId = function () {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/customercontact/bycustomerid", { headers: headers });
    };
    ApartmentComplexInformationService.prototype.getCustomerContactsByCustomerIdSalesRole = function (salesroleid) {
        var headers = new HttpHeaders();
        return this.http.get(environment.api + "api/customercontact/bycustomerid/" + salesroleid, { headers: headers });
    };
    ApartmentComplexInformationService.prototype.saveContact = function (CustomerContact) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        //var returnID = this.http.post(`${environment.api}api/contact`, CustomerContact, { headers: headers });
        // CustomerContact.contactId = returnID;
        return this.http.post(environment.api + "api/contact", CustomerContact, { headers: headers });
    };
    return ApartmentComplexInformationService;
}());
export { ApartmentComplexInformationService };
