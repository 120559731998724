import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(private http: HttpClient) {}

  shortenUrl(longUrl: string): Observable<{ shortUrl: string }> {
    return this.http.get<{ shortUrl: string }>(
      `${
        environment.apiQwikkitOrdering
      }api/qrcode/shorten?longUrl=${encodeURIComponent(longUrl)}`
    );
  }

  getQrCodeUrl(shortUrl: string): string {
    const shortId = shortUrl.replace('https://cutt.ly/', '');
    return `https://cutt.ly/api/qr-code/api.php?short=${shortId}`;
  }

  generateQrCode(
    apartmentComplexID: number,
    apartmentId: number
  ): Observable<{ shortUrl: string; qrCodeBase64: string }> {
    return this.http.get<{ shortUrl: string; qrCodeBase64: string }>(
      `${environment.apiQwikkitOrdering}api/qrcode/generate?apartmentComplexId=${apartmentComplexID}&apartmentId=${apartmentId}`
    );
  }

  resolveShortUrl(
    shortUrl: string
  ): Observable<{ resolvedUrl: string; token: string; isValidToken: boolean }> {
    return this.http.get<{
      resolvedUrl: string;
      token: string;
      isValidToken: boolean;
    }>(
      `${
        environment.apiQwikkitOrdering
      }api/qrcode/resolve?shortUrl=${encodeURIComponent(shortUrl)}`
    );
  }
}
