<block-ui>
  <!-- Login Wrapper -->
  <ng-container *ngIf="isLogin; else nonLoginTemplate">
    <div class="login-wrapper">
      <router-outlet (activate)="onRouterChange($event)"></router-outlet>
    </div>
    <app-login-footer></app-login-footer>
  </ng-container>

  <ng-template #nonLoginTemplate>
    <div class="wrapper" id="wrapper" appScreenBehavior>
      <ng-container *ngTemplateOutlet="showDefaultHeader ? defaultHeader : customHeader"></ng-container>
      <router-outlet (activate)="onRouterChange($event)"></router-outlet>
    </div>
    <app-footer></app-footer>
  </ng-template>

  <ng-template #defaultHeader>
    <app-header [notifyList]="alertNotifyList"></app-header>
  </ng-template>

  <ng-template #customHeader>
    <app-custom-header></app-custom-header>
  </ng-template>
</block-ui>