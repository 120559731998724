<div class="modal-body palletize-body palletizeModal" *ngIf="palletizeInformation">
  <div class="text-right">
    <span class="close-button" (click)="closeModal()" aria-hidden="true">&times;</span>
  </div>
  <h1 class="h1-styling center">Palletization Info</h1>
  <h5 class="h5-styling center">Shipping Address</h5>
  <form class="address-form row" (keyup)="validateForm()" (change)="validateForm()">
    <div class="form-colum col-6">
      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressName}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressName"
          [formControl]="palletize_form.controls['name']" #shippingAddressName name="name" maxlength="50"
          class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressLine1}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressLine1"
          [formControl]="palletize_form.controls['addressLine1']" #shippingAddressLine1 type="text" name="addressLine1"
          required class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressLine2}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressLine2"
          [formControl]="palletize_form.controls['addressLine2']" #shippingAddressLine2 name="addressLine2" type="text"
          class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressZipCode}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressZipCode"
          [formControl]="palletize_form.controls['zipCode']" type="text" name="zipCode" required
          pattern="[0-9]+-?[0-9]{4,}" class="col-12">
      </mat-form-field>
    </div>
    <div class="form-colum col-6">
      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressCity}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressCity"
          [formControl]="palletize_form.controls['city']" #shippingAddressCity name="city" required type="text"
          class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressState}}</mat-label>
        <mat-select required name="state" [(ngModel)]="palletizeInformation.shippingAddressState"
          (ngModelChange)="setState($event)" [formControl]="palletize_form.controls['state']">
          <mat-option [disabled]="true">State*</mat-option>
          <mat-option *ngFor="let state of states" [value]="state.name">{{state.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressPhone}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingAddressPhone"
          [formControl]="palletize_form.controls['phone']" #shippingAddressPhone
          (keypress)="phone(shippingAddressPhone.value, '1', $event)" name="phone" type="text"
          pattern="\d{3}[\-]\d{3}[\-]\d{4}" maxlength="12" class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.shippingAddressNotes}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.shippingInstructions"
          [formControl]="palletize_form.controls['shippingInstructions']" #shippingAddressInstructions
          name="shippingInstructions" type="text" class="col-12">
      </mat-form-field>
    </div>
    <div class="contactInformation">
      <h5 class="h5-styling center">Contacts Information</h5>
    </div>
    <div class="form-colum col-6">
      <p class="p-styling">For delivery at install location</p>

      <mat-form-field>
        <mat-label>{{palletizeContactPlaceholder.fullName}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[0].fullName"
          [formControl]="palletize_form.controls['contactName']" name="contactName" required type="text" class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{palletizeContactPlaceholder.phoneNumber}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[0].phoneNumber"
          [formControl]="palletize_form.controls['contactPhone']" name="contactPhone"
          (keypress)="phone(palletizeInformation.contactList[0].phoneNumber, '2', $event)" required type="text"
          pattern="\d{3}[\-]\d{3}[\-]\d{4}" maxlength="12" class="col-12">
      </mat-form-field>
    </div>

    <div class="form-colum col-6">
      <p class="col-12"></p>
      <mat-form-field class="margin-top-29">
        <mat-label>{{palletizeContactPlaceholder.emailAddres}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[0].emailAddres"
          [formControl]="palletize_form.controls['contactEmail']" name="contactEmail" required type="text"
          class="col-12">
      </mat-form-field>
    </div>

    <div class="form-colum col-6">
      <p class="p-styling">Authorization to make delivery changes</p>

      <mat-form-field>
        <mat-label>{{palletizeContactPlaceholder.fullName}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[1].fullName"
          [formControl]="palletize_form.controls['authorizationContactName']" name="contactName" required type="text"
          class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{palletizeContactPlaceholder.phoneNumber}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[1].phoneNumber"
          [formControl]="palletize_form.controls['authorizationContactPhone']" name="contactPhone"
          (keypress)="phone(palletizeInformation.contactList[1].phoneNumber, '3', $event)" required type="text"
          pattern="\d{3}[\-]\d{3}[\-]\d{4}" maxlength="12" class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{shippingAddressPlaceholder.entranceCode}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.entranceCode"
          [formControl]="palletize_form.controls['entranceCode']" name="entranceCode" type="text" class="col-12">
      </mat-form-field>
    </div>

    <div class="form-colum col-6">
      <p class="col-12">&nbsp;</p>
      <mat-form-field class="margin-top-36">
        <mat-label>{{palletizeContactPlaceholder.emailAddres}}</mat-label>
        <input matInput [(ngModel)]="palletizeInformation.contactList[1].emailAddres"
          [formControl]="palletize_form.controls['authorizationcontactEmail']" name="contactEmail" required type="text"
          class="col-12">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Delivery date</mat-label>
        <input #dateField matInput required [formControl]="date" [matDatepicker]="dp" [min]="minDeliveryDate" [matDatepickerFilter]="datesFilter"
          [(ngModel)]="palletizeInformation.deliveryDate" (dateChange)="validateForm()" [min]="currentDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp disabled="false" (opened)="scrollToField(dateField)">
        </mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div class="Yes">
    <p class="question p-styling left">Will you require a liftgate at the time of delivery?</p>
    <div class="groupCheck" (click)="palletizeInformation.isLiftgateRequired = true; validateForm()">
      <label style="margin-right: 5px;">Yes</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="palletizeInformation.isLiftgateRequired ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
    &nbsp;
    <div class="groupCheck" (click)="palletizeInformation.isLiftgateRequired = false; validateForm()">
      <label style="margin-right: 5px;">No</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="!palletizeInformation.isLiftgateRequired ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
  </div>
  <div class="Yes">
    <p class="question p-styling left">At the time of delivery, will there be access to the dock and can it be accessed
      with a 53 ft.
      trailer?</p>
    <div class="groupCheck" (click)="palletizeInformation.isLimitedAccessToAddress = true; validateForm()">
      <label style="margin-right: 5px;">Yes</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="palletizeInformation.isLimitedAccessToAddress ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
    &nbsp;
    <div class="groupCheck" (click)="palletizeInformation.isLimitedAccessToAddress = false; validateForm()">
      <label style="margin-right: 5px;">No</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="!palletizeInformation.isLimitedAccessToAddress ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
  </div>
  <div class="Yes">
    <p class="question p-styling left">Can the delivery arrive early if kits are finished ahead of schedule?</p>
    <div class="groupCheck" (click)="palletizeInformation.canDelieverEarly = true; validateForm()">
      <label style="margin-right: 5px;">Yes</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="palletizeInformation.canDelieverEarly ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
    &nbsp;
    <div class="groupCheck" (click)="palletizeInformation.canDelieverEarly = false; validateForm()">
      <label style="margin-right: 5px;">No</label>
      <div style="display: inline-flex; vertical-align: middle;" class="btn-radio"
        [ngClass]="!palletizeInformation.canDelieverEarly ? 'active':'inactive'">
        <div class="dot"></div>
      </div>
    </div>
  </div>
  <h5 class="h5-styling center">Choose the type of Palletization</h5>
  <div class="palletizeOptions active" *ngFor="let option of data.palletizeOptions; let i = index;">
    <div class="btn-radio col-2" [ngClass]="optionSelect[i] ? 'active': ''"
      (click)="choseKitOptions(i, option.palletizeOptionId)">
      <div class="dot"></div>
    </div>
    <div (click)="choseKitOptions(i, option.palletizeOptionId)">
      <p [ngClass]="optionSelect[i] ? 'active': ''" class="p-styling no-margin left" style="padding-left: 35px;"
        [innerText]="option.optionName +' - ' + option.optionDescription"></p>
    </div>
  </div>
  <div style="text-align: center;">
    <button class="buttons" [ngClass]="valid ? 'active' : 'inactive'" (click)="submitForm()">Save</button>
  </div>
</div>

<ng-template #fedexModal>
  <div class="modal-body">
    <h1 class="h1-styling center">Suggested Standardized Address</h1>
    <p class="warningMessage">{{this.badAddressMessage}}</p>
    <app-spinner style="position: absolute; top: 430px; left: 331px; z-index: 100;" #fedexSpinner></app-spinner>
    <div class="address-container">
      <div class="address" (click)="keepAddress = false" *ngIf="match">
        <div class="btn-radio" [ngClass]="keepAddress === false ? 'active': ''">
          <div class="dot"></div>
        </div>
        <p>{{match}}</p>
      </div>
      <div class="address" (click)="keepAddress = true">
        <div class="btn-radio" [ngClass]="keepAddress ? 'active': ''">
          <div class="dot"></div>
        </div>
        <p>Keep my address data.</p>
      </div>
    </div>
    <div style="text-align: center;">
      <button class="buttons" [ngClass]="keepAddress === undefined ? 'inactive' : ''"
        (click)="setFedex(keepAddress)">Continue</button>
    </div>
  </div>
</ng-template>
