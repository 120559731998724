import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import { Credentials } from '../../shared/types/credentials';
import { environment } from '../../../environments/environment';
import { JsonWebToken, Token } from '../types/token';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerLogin } from '../../shared/types/customer-login'
import { CustomerAccess } from '../types/login';
import { LoginVerbiage } from '../types/login-verbiage';

@Injectable()
export class LoginService {
  private params: URLSearchParams = new URLSearchParams();
  private header = new HttpHeaders(
    {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Charset': 'utf-8'
    });
  private options = { headers: this.header };

  public redirectUrl: string;
  public hasLedgeEntries = new BehaviorSubject(false);
  public accessCustomerList = new BehaviorSubject<CustomerAccess[]>([]);
  public authenticate = new BehaviorSubject(false);
  public customerLedge: any;
  public multipleAccounts: any;
  public dictionary: any;
  public currentToken: any;


  constructor(private http: HttpClient, private router: Router, public toastr: ToastrService) { }

  signIn(credentials: Credentials) {
    for (const key in credentials) {
      if (credentials.hasOwnProperty(key)) {
        this.params.set(key, credentials[key]);
      }
    }
    return this.http.post(`${environment.api}token`, this.params.toString(), this.options)
      .map(async token => {
        this.currentToken = token;
        localStorage.setItem('token', JSON.stringify(token));

        // Get token for new API
        await this.logInQwikkitOrdering(credentials);

        // Verify if is a multiple user or CRS user
        await this.userHasMultipleAccounts();

        // Verify if is a Sale Person user
        if (this.accessCustomerList.value.length === 0) {
          await this.userSalePersonHasMultipleAccounts();
        }

        if (this.accessCustomerList.value.length === 0) {
          this.authenticate.next(true);
          this.getLedgerEntries();
        }

        return token;
      });
  }

  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenQwikkitOrdering');
    this.authenticate.next(false);
    this.toastr.error('please re-sign in', 'Your session has expired',
      {
        easeTime: 3000,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-left'
      }).onShown.subscribe(() => {
        this.router.navigate(['/login']);
      });
  }

  cleanShoppingCart() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let searchParams: any = new Object();
    const requestOptions = { headers, params: searchParams };
    return this.http.delete(`${environment.api}api/orders/deletecart`, requestOptions).toPromise();
  }

  getToken(): any | boolean {
    const localStorageToken = JSON.parse(localStorage.getItem('token'));
    if (this.currentToken == null) {
      this.currentToken = JSON.parse(localStorage.getItem('token'));
    }
    if (this.currentToken != null && localStorageToken != null &&
      `${this.currentToken.token_type} ${this.currentToken.access_token}` !=
      `${JSON.parse(localStorage.getItem('token')).token_type} ${JSON.parse(localStorage.getItem('token')).access_token}`) {
      window.location.replace(window.location.origin + '/dashboard');
    }
    else {
      return JSON.parse(localStorage.getItem('token')) || false;
    }
  }

  getTokenQwikkitOrderingIsExpired(): boolean {
    const tokenStorage = localStorage.getItem('tokenQwikkitOrdering');
    if (tokenStorage !== null && tokenStorage !== '') {
      const tokenStorageParsed = JSON.parse(tokenStorage);
      if (tokenStorageParsed && tokenStorageParsed.value) {
        const arrayToken = tokenStorageParsed.value.split('.');
        const tokenPayload = JSON.parse(atob(arrayToken[1]));
        if (tokenPayload && tokenPayload.exp) {
          return Math.floor(new Date().getTime() / 1000) >= tokenPayload.exp;
        }
      }
    }
    return true;
  }


  getAccount(): Observable<boolean> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.api}api/account`, { headers: headers })
      .map(() => true).catch((err) => {
        return Observable.of(false);
      });
  }

  async getLedgerEntries() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    await this.http.get(`${environment.api}api/orders/dueleadgeEntries`, { headers: headers })
      .toPromise()
      .then(ledgers => {
        this.customerLedge = ledgers;
        this.hasLedgeEntries.next(this.customerLedge.length > 0);
      });
  }

  async userHasMultipleAccounts() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    await this.http.get(`${environment.api}api/account/checkmultipleaccounts`, { headers: headers })
      .toPromise()
      .then((accounts: CustomerAccess[]) => {
        this.multipleAccounts = accounts;
        this.accessCustomerList.next(accounts);
      });
  }

  async userSalePersonHasMultipleAccounts() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    await this.http.get(`${environment.api}api/account/getAccountsBySalesPerson`, { headers: headers })
      .toPromise()
      .then((accounts: CustomerAccess[]) => {
        this.multipleAccounts = accounts;
        this.accessCustomerList.next(accounts);
      });
  }

  async listMultipleAccounts() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.multipleAccounts = await this.http.get(`${environment.api}api/account/checkmultipleaccounts`, { headers: headers }).toPromise();
  }

  async setAccount(customerId: number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    await this.http.post(`${environment.api}api/account/setaccount?customerID=${customerId}`, { headers })
      .toPromise()
      .then((updateAccount: any) => {
        var currentToken = JSON.parse(localStorage.getItem("token"));
        currentToken.companyname = updateAccount.companyname;
        currentToken.customernumber = updateAccount.customernumber;
        currentToken.role = updateAccount.role;
        currentToken.isCSR = updateAccount.isCSR;
        localStorage.setItem("token", JSON.stringify(currentToken));

        this.authenticate.next(true);
        this.getLedgerEntries();
      });
  }

  verifyPass(state, pass) {
    let str;
    switch (state) {
      case 'pass-number':
        str = /^((?=.*\d)|(?=.*\W+))/;
        break;
      case 'length':
        str = /^(?=^.{8,}$)/;
        break;
      case 'low-uper':
        str = /^(?![.\n])(?=.*[A-Z])(?=.*[a-z])/;
        break;
      default:
        break;
    }
    return !str.test(pass);
  }

  async recordTermsAndCondition() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    await this.http.post(`${environment.api}api/account/recordtermsandcondition`, { headers }).toPromise();
  }

  async customerLogin(customerLogin: CustomerLogin) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${environment.api}api/account/customerlogin`, customerLogin, { headers }).toPromise();
  }

  async logInQwikkitOrdering(credentials: Credentials) {
    await this.http.post(`${environment.apiQwikkitOrdering}api/Auth/Token`, credentials, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .toPromise()
      .then((token: JsonWebToken) => {
        if (token && token.value && token.value !== '') {
          localStorage.setItem('tokenQwikkitOrdering', JSON.stringify(token));
        }
      })
      .catch(err => console.error('Error: tokenQwikkitOrdering => ', err));
  }

  getVerbiage(): Observable<LoginVerbiage> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const requestOptiosn = { headers };
    return this.http.get<LoginVerbiage>(`${environment.api}api/account/verbiage`, requestOptiosn);
  }
}
