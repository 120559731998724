var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ApartmentComplexService } from '../shared/services/apartment-complex.service';
import { LoginService } from '../shared/services/login.service';
import { LandingApartmentComplexService } from '../shared/services/landing-apartment-complex.service';
import { ModalDirective } from 'ngx-bootstrap';
import { AlertService } from '../shared/services/alert.service';
import * as moment from 'moment';
import { ModalComponent } from 'app/modal/modal.component';
import { DomSanitizer } from '@angular/platform-browser';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(loginService, router, apartmentComplexService, alertService, sanitizer, landingApartmentComplexService) {
        var _this = this;
        this.loginService = loginService;
        this.router = router;
        this.apartmentComplexService = apartmentComplexService;
        this.alertService = alertService;
        this.sanitizer = sanitizer;
        this.landingApartmentComplexService = landingApartmentComplexService;
        this.visit = 'VISIT QWIKKIT MAIN';
        this.signOut = 'Sign Out';
        this.logged = false;
        this.details = false;
        this.navbar = true;
        this.myDashboard = 'My Dashboard';
        this.support = 'HELP / SUPPORT';
        this.viewNotifyPanel = false;
        this.notifyList = [];
        this.router.events.subscribe(function (params) {
            if (params instanceof RoutesRecognized) {
                if (params.url === '/login' || params.url === '/account1234') {
                    _this.details = false;
                }
            }
        });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.loginService.getToken() !== false) {
            this.loginService.authenticate.next(true);
            this.checkLedgeEntries();
            this.landingApartmentComplexService.setUI(0);
        }
        this.loginService.authenticate.subscribe(function (value) { return _this.logged = value; }, function (err) { return console.error(err); });
        this.landingApartmentComplexService.isDetails.subscribe(function (value) { return _this.details = value; }, function (err) { return console.error(err); });
        this.loginService.hasLedgeEntries.subscribe(function (value) { return _this.hasLedgeEntries = value; }, function (err) { return console.error(err); });
    };
    HeaderComponent.prototype.logOut = function () {
        this.loginService.logOut();
        this.landingApartmentComplexService.isDetails.next(false);
        this.router.navigate(['/login']);
    };
    HeaderComponent.prototype.onEditAccount = function () {
        this.landingApartmentComplexService.isDetails.next(true);
        this.router.navigate(['/account1234/edit']);
    };
    HeaderComponent.prototype.goDashboard = function () {
        this.landingApartmentComplexService.isDetails.next(false);
        this.router.navigate(['/dasboard']);
    };
    HeaderComponent.prototype.goSupport = function () {
        window.open('https://cinchkit.freshdesk.com/support/home', '_blank');
    };
    HeaderComponent.prototype.home = function () {
        this.router.navigate(['/dasboard']);
    };
    HeaderComponent.prototype.openNewTab = function () {
        window.open('https://qwikkit.com/', '_blank');
    };
    HeaderComponent.prototype.checkLedgeEntries = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.apartmentComplexService.getDueLedgeEntries().toPromise()];
                    case 1:
                        _a.customerLedge = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        console.error('Error loading leadge entries.', err_1);
                        return [3 /*break*/, 3];
                    case 3:
                        if (this.customerLedge.length > 0) {
                            this.hasLedgeEntries = true;
                        }
                        else {
                            this.hasLedgeEntries = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.showNotify = function () {
        this.viewNotifyPanel = !this.viewNotifyPanel;
        this.childModal.config.backdrop = false;
        this.childModal.show();
    };
    HeaderComponent.prototype.markReadNotification = function (alert) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            var notifiDismiss;
                            alert.readBy = _this.loginService.getToken().userName;
                            alert.readDate = new Date(moment().format());
                            _this.alertService.putMarkReadAlert(alert).subscribe(function (response) { return notifiDismiss = response; }, function (err) {
                                console.error(err);
                                reject();
                            }, function () { return resolve(notifiDismiss); });
                        }).then(function (alertDelete) {
                            var idx = _this.notifyList.findIndex(function (a) { return a.alertId === alertDelete.alertId; });
                            if (idx !== -1) {
                                _this.notifyList.splice(idx, 1);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.markReadAllNotification = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _loop_1, _i, _a, alert_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _loop_1 = function (alert_1) {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                                            var notifiDismiss;
                                            alert_1.readBy = _this.loginService.getToken().userName;
                                            alert_1.readDate = new Date(moment().format());
                                            _this.alertService.putMarkReadAlert(alert_1).subscribe(function (response) { return notifiDismiss = response; }, function (err) {
                                                console.error(err);
                                                reject();
                                            }, function () { return resolve(notifiDismiss); });
                                        }).then(function (alertDelete) {
                                            var idx = _this.notifyList.findIndex(function (a) { return a.alertId === alertDelete.alertId; });
                                            if (idx !== -1) {
                                                _this.notifyList.splice(idx, 1);
                                            }
                                        })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _i = 0, _a = this.notifyList;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        alert_1 = _a[_i];
                        return [5 /*yield**/, _loop_1(alert_1)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.onPlayWatchVideo = function () {
        this.orderingVideo.showChildModal();
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/407828664');
    };
    HeaderComponent.prototype.payNow = function () {
        window.open("https://qwikkit.com/payments/", '_blank');
    };
    return HeaderComponent;
}());
export { HeaderComponent };
