<div class="container">
  <div class="text-center">
    <h4 class="title-md-qwikkit">Reorder Similar</h4>
  </div>
  <p class="text-qwikkit mt-3">
    This process copies an existing order and allows you to edit it if needed and then place new orders. Please provide
    a unit number for the new orders.
  </p>
  <form [formGroup]="similarOrderForm">
    <div class="row justify-content-md-center">
      <div class="col-9 mb-3">
        <mat-form-field class="full-width">
          <mat-label class="lbl-control">Apartment Number</mat-label>
          <input matInput type="text" name="apartmentNumber" [formControl]="similarOrderForm.get('apartmentNumber')"
            [maxLength]="50">
          <mat-error *ngIf="onValidateControl('apartmentNumber', 'required')">*This field is required.</mat-error>
          <mat-error *ngIf="onValidateControl('apartmentNumber', 'apartmentExist')">{{ apartmentNumberMessage
            }}</mat-error>
          <mat-error *ngIf="onValidateControl('apartmentNumber', 'specialChar')">Specials characters are not allowed. {{ charNotAllowed.join(' ') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col-9 mb-3">
        <span class="small strong-text">* These apartments are going to be created with this Po Number</span>
        <mat-form-field class="full-width">
          <mat-label class="lbl-control">PO Number</mat-label>
          <input matInput type="text" name="poNumber" [maxLength]="50" [formControl]="similarOrderForm.get('poNumber')">
          <mat-error *ngIf="onValidateControl('poNumber', 'required')">*This field is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col-9 mb-3">
        <mat-form-field class="full-width">
          <mat-label class="lbl-control">Type</mat-label>
          <input matInput type="text" name="apartmentType" [maxLength]="50"
            [formControl]="similarOrderForm.get('apartmentType')">
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col-9 mb-3">
        <mat-form-field class="full-width">
          <mat-label class="lbl-control">Quantity</mat-label>
          <input matInput type="number" name="quantity" [maxLength]="50" (keydown)="onKeyDownOnlyNumbers($event)"
            [formControl]="similarOrderForm.get('quantity')">
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col-9 mb-3">
        <mat-checkbox name="addOrderToShoppingCart" [formControl]="similarOrderForm.get('addOrderToShoppingCart')">
          <span>Add the new unit(s) to my shopping cart</span>
        </mat-checkbox>
      </div>
    </div>
  </form>
  <div class="row justify-content-end mt-2">
    <div class="col-8 text-right">
      <button style="margin-right: 1rem;" type="button" class="buttons" (click)="onCloseModal()">Cancel</button>
      <button style="margin-right: 1rem;" type="button" class="buttons" [ngClass]="{'inactive': !onAllowSaveForm()}"
        [disabled]="!onAllowSaveForm()" (click)="onCreateSimilarOrder()">Order Similar</button>
    </div>
  </div>
</div>