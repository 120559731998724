/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "./qr-modal.component";
import * as i5 from "@angular/common/http";
var styles_QrModalComponent = [i0.styles];
var RenderType_QrModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrModalComponent, data: {} });
export { RenderType_QrModalComponent as RenderType_QrModalComponent };
function View_QrModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["qrCanvas", 1]], null, 0, "canvas", [], null, null, null, null, null))], null, null); }
function View_QrModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "QR Code"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.qrCodeImage; _ck(_v, 0, 0, currVal_0); }); }
function View_QrModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Generating QR Code..."]))], null, null); }
export function View_QrModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { qrCanvas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Scan QR Code"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "mat-dialog-content", [["class", "qr-container mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrModalComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.qrCodeImage; _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.qrCodeImage; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.qrCodeImage; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); }); }
export function View_QrModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-modal", [], null, null, null, View_QrModalComponent_0, RenderType_QrModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.QrModalComponent, [i2.MatDialogRef, i5.HttpClient, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrModalComponentNgFactory = i1.ɵccf("app-qr-modal", i4.QrModalComponent, View_QrModalComponent_Host_0, {}, {}, []);
export { QrModalComponentNgFactory as QrModalComponentNgFactory };
