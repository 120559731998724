<footer class="footer">
  <div class="container">
    <div class="row align-items-center">
      <!-- Logo Section -->
      <div class="col-12 col-md-6 text-start">
        <img src="assets/images/Qwikkit-Logo-wTag-Grey-White-RGB.png" alt="Qwikkit Logo" class="logo" width="230">
      </div>

      <!-- Phone Section -->
      <div class="col-12 col-md-6 text-end text-md-end">
        <div class="phone-section">
          <div class="phone-content">
            <i class="material-icons">&#xE0B0;</i>
            <div class="phone-texts">
              <span class="phone-text">{{phone}}</span>
              <span class="phone-text">{{phone2}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>