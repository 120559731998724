/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./twilio-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./twilio-modal.component";
import * as i6 from "../services/twilio.service";
var styles_TwilioModalComponent = [i0.styles];
var RenderType_TwilioModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TwilioModalComponent, data: {} });
export { RenderType_TwilioModalComponent as RenderType_TwilioModalComponent };
function View_TwilioModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please enter a valid U.S. phone number in the format 123-456-7890. "]))], null, null); }
export function View_TwilioModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Send a Text Message"])), (_l()(), i1.ɵeld(3, 0, null, null, 17, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(7, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(9, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["for", "phoneNumber"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Phone Number"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "input", [["class", "form-control"], ["formControlName", "phoneNumber"], ["id", "phoneNumber"], ["placeholder", "Enter phone number"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(16, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(18, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TwilioModalComponent_1)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(22, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["mat-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(26, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_8 = _co.phoneForm; _ck(_v, 7, 0, currVal_8); var currVal_16 = "phoneNumber"; _ck(_v, 16, 0, currVal_16); var tmp_17_0 = null; var currVal_17 = ((((tmp_17_0 = _co.phoneForm.get("phoneNumber")) == null) ? null : tmp_17_0.invalid) && (((tmp_17_0 = _co.phoneForm.get("phoneNumber")) == null) ? null : tmp_17_0.touched)); _ck(_v, 20, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 18).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 18).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 18).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 18).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 18).ngClassValid; var currVal_14 = i1.ɵnov(_v, 18).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 18).ngClassPending; _ck(_v, 13, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_18 = _co.isLoading; _ck(_v, 23, 0, currVal_18); var currVal_19 = (_co.phoneForm.invalid || _co.isLoading); _ck(_v, 25, 0, currVal_19); var currVal_20 = (_co.isLoading ? "Sending..." : "Send"); _ck(_v, 26, 0, currVal_20); }); }
export function View_TwilioModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-twilio-modal", [], null, null, null, View_TwilioModalComponent_0, RenderType_TwilioModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.TwilioModalComponent, [i3.FormBuilder, i2.MatDialogRef, i6.TwilioService, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var TwilioModalComponentNgFactory = i1.ɵccf("app-twilio-modal", i5.TwilioModalComponent, View_TwilioModalComponent_Host_0, {}, {}, []);
export { TwilioModalComponentNgFactory as TwilioModalComponentNgFactory };
