import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TwilioService } from '../../shared/services/twilio.service';

@Component({
  selector: 'app-twilio-modal',
  templateUrl: './twilio-modal.component.html',
  styleUrls: ['./twilio-modal.component.scss']
})
export class TwilioModalComponent {
  phoneForm: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TwilioModalComponent>,
    private twilioService: TwilioService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.phoneForm = this.fb.group({
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}-\d{3}-\d{4}$/) 
        ]
      ]
    });

    const phoneNumberControl = this.phoneForm.get('phoneNumber');
  if (phoneNumberControl) {
    phoneNumberControl.valueChanges.subscribe((value) => {
      this.formatPhoneNumber(value);
    });
  }

  }

  formatPhoneNumber(value: string): void {
    if (!value) return;

    const cleanedValue = value.replace(/\D+/g, '');

    let formattedValue = '';
    if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.substring(0, 3); 
    }
    if (cleanedValue.length >= 4) {
      formattedValue += '-' + cleanedValue.substring(3, 6); 
    }
    if (cleanedValue.length >= 7) {
      formattedValue += '-' + cleanedValue.substring(6, 10); 
    }

    const phoneNumberControl = this.phoneForm.get('phoneNumber');
    if (phoneNumberControl) {
      phoneNumberControl.setValue(formattedValue, { emitEvent: false });
    }
    
  }

  close(): void {
    this.dialogRef.close();
  }

  sendText(): void {
    if (this.phoneForm.invalid) {
      alert('Please enter a valid U.S. phone number.');
      return;
    }

    
  }
}
