<h2 mat-dialog-title>Send a Text Message</h2>
<mat-dialog-content>
  <form [formGroup]="phoneForm">
    <div class="form-group">
      <label for="phoneNumber">Phone Number</label>
      <input
        id="phoneNumber"
        type="text"
        formControlName="phoneNumber"
        class="form-control"
        placeholder="Enter phone number"
      />
      <div *ngIf="phoneForm.get('phoneNumber')?.invalid && phoneForm.get('phoneNumber')?.touched" class="error">
        Please enter a valid U.S. phone number in the format 123-456-7890.
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()" [disabled]="isLoading">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="phoneForm.invalid || isLoading"
    (click)="sendText()"
  >
    {{ isLoading ? 'Sending...' : 'Send' }}
  </button>
</mat-dialog-actions>
