var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
// Material
import { MatDialogRef } from '@angular/material';
// Services
import { ApartmentComplexService } from '../shared/services/apartment-complex.service';
// Rxjs
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
var OrderShoppingCartSimilarModalComponent = /** @class */ (function () {
    function OrderShoppingCartSimilarModalComponent(dialogRef, data, apartmentComplexService, formBuilder) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.apartmentComplexService = apartmentComplexService;
        this.formBuilder = formBuilder;
        this.apartmentNumberValid = true;
        this.apartmentNumberMessage = '';
        // List
        this.charNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', 'v'];
        this.charNotAllowed = [',', '"', '!', '"', '$', '%', '&', '\'', '*', ',', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_', '`', '{', '|', '}', '~', '\\'];
    }
    OrderShoppingCartSimilarModalComponent.prototype.ngOnInit = function () {
        this.onInitSimilarForm();
    };
    OrderShoppingCartSimilarModalComponent.prototype.onInitSimilarForm = function () {
        this.similarOrderForm = this.formBuilder.group({
            apartmentNumber: new FormControl('', {
                validators: [Validators.required, this.onValidateSpecialCharsInApartmentNumber()],
                asyncValidators: [this.onValidateApartmentNumberAsync()],
                updateOn: 'blur'
            }),
            poNumber: ['', this.data.poNumberIsRequired && this.data.poNumberIsRequired ? Validators.required : null],
            apartmentType: [''],
            quantity: [1, [Validators.required, Validators.min(1)]],
            addOrderToShoppingCart: [false]
        });
    };
    OrderShoppingCartSimilarModalComponent.prototype.onValidateApartmentNumberAsync = function () {
        var _this = this;
        return function (control) {
            return control.value !== '' ?
                _this.apartmentComplexService.validApartment(control.value, _this.data.shoppingCartItem.apartmentComplexId, 0)
                    .pipe(map(function (result) {
                    if (result && !result.isValid) {
                        _this.apartmentNumberMessage = result.message;
                        return ({ apartmentExist: true });
                    }
                    else {
                        return null;
                    }
                }), catchError(function () { return of(null); })) :
                of(null);
        };
    };
    OrderShoppingCartSimilarModalComponent.prototype.onValidateSpecialCharsInApartmentNumber = function () {
        var _this = this;
        return function (control) {
            for (var _i = 0, _a = _this.charNotAllowed; _i < _a.length; _i++) {
                var char = _a[_i];
                if (control.value.includes(char)) {
                    return { specialChar: true };
                }
            }
        };
    };
    OrderShoppingCartSimilarModalComponent.prototype.onValidateControl = function (controlName, validation) {
        var selectControl = this.similarOrderForm.get(controlName);
        if (selectControl) {
            return selectControl.hasError(validation);
        }
    };
    OrderShoppingCartSimilarModalComponent.prototype.onKeyDownOnlyNumbers = function (event) {
        if (!this.charNumbers.includes(event.key)) {
            event.preventDefault();
            return false;
        }
    };
    OrderShoppingCartSimilarModalComponent.prototype.onAllowSaveForm = function () {
        return this.similarOrderForm.touched && this.similarOrderForm.valid;
    };
    OrderShoppingCartSimilarModalComponent.prototype.onCreateSimilarOrder = function () {
        this.data.quantity = this.similarOrderForm.get('quantity').value !== null ? this.similarOrderForm.get('quantity').value : 1;
        this.data.addOrderToShoppingCart = this.similarOrderForm.get('addOrderToShoppingCart').value;
        this.data.apartment = {
            id: this.data.shoppingCartItem.apartmentId,
            apartmentComplexID: this.data.shoppingCartItem.apartmentComplexId,
            apartmentNumber: this.similarOrderForm.get('apartmentNumber').value,
            apartmentType: this.similarOrderForm.get('apartmentType').value,
            customerPONumber: this.similarOrderForm.get('poNumber').value,
            noChargeReasonCodeId: this.data.noChargeReasonCodeId && this.data.noChargeReasonCodeId > 0 ? this.data.noChargeReasonCodeId : null
        };
        this.dialogRef.close(__assign({}, this.data, { state: 'success' }));
    };
    OrderShoppingCartSimilarModalComponent.prototype.onCloseModal = function () {
        this.dialogRef.close(__assign({}, this.data, { state: 'cancel' }));
    };
    return OrderShoppingCartSimilarModalComponent;
}());
export { OrderShoppingCartSimilarModalComponent };
