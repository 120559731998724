/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./login-footer/login-footer.component.ngfactory";
import * as i4 from "./login-footer/login-footer.component";
import * as i5 from "./shared/directives/screen-behavior.directive";
import * as i6 from "./shared/services/shared.service";
import * as i7 from "@angular/common";
import * as i8 from "./footer/footer.component.ngfactory";
import * as i9 from "./footer/footer.component";
import * as i10 from "./header/header.component.ngfactory";
import * as i11 from "./header/header.component";
import * as i12 from "./shared/services/login.service";
import * as i13 from "./shared/services/apartment-complex.service";
import * as i14 from "./shared/services/alert.service";
import * as i15 from "@angular/platform-browser";
import * as i16 from "./shared/services/landing-apartment-complex.service";
import * as i17 from "./app-custom-header/app-custom-header.component.ngfactory";
import * as i18 from "./app-custom-header/app-custom-header.component";
import * as i19 from "../../node_modules/ng-block-ui/lib/components/block-ui/block-ui.component.ngfactory";
import * as i20 from "ng-block-ui/lib/components/block-ui/block-ui.component";
import * as i21 from "ng-block-ui/lib/services/block-ui-instance.service";
import * as i22 from "./app.component";
import * as i23 from "ngx-toastr";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "login-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onRouterChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-login-footer", [], null, null, null, i3.View_LoginFooterComponent_0, i3.RenderType_LoginFooterComponent)), i1.ɵdid(5, 114688, null, 0, i4.LoginFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["appScreenBehavior", ""], ["class", "wrapper"], ["id", "wrapper"]], null, [["window", "load"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:load" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onLoad() !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i5.ScreenBehaviorDirective, [i6.SharedService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(3, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onRouterChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(7, 114688, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showDefaultHeader ? i1.ɵnov(_v.parent, 5) : i1.ɵnov(_v.parent, 6)); _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i10.View_HeaderComponent_0, i10.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i11.HeaderComponent, [i12.LoginService, i2.Router, i13.ApartmentComplexService, i14.AlertService, i15.DomSanitizer, i16.LandingApartmentComplexService], { notifyList: [0, "notifyList"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertNotifyList; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-header", [], null, null, null, i17.View_AppCustomHeaderComponent_0, i17.RenderType_AppCustomHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i18.AppCustomHeaderComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "block-ui", [], null, null, null, i19.View_BlockUIComponent_0, i19.RenderType_BlockUIComponent)), i1.ɵdid(1, 114688, null, 0, i20.BlockUIComponent, [i21.BlockUIInstanceService], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["nonLoginTemplate", 2]], 0, 0, null, View_AppComponent_2)), (_l()(), i1.ɵand(0, [["defaultHeader", 2]], 0, 0, null, View_AppComponent_4)), (_l()(), i1.ɵand(0, [["customHeader", 2]], 0, 0, null, View_AppComponent_5))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.isLogin; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i22.AppComponent, [i2.Router, i23.ToastrService, i12.LoginService, i14.AlertService, i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i22.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
