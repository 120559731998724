<div class="container">
  <div class="col-12 text-center">
    <h4 mat-dialog-title>TERMS AND CONDITIONS</h4>
  </div>
</div>
<mat-dialog-content cdkFocusInitial cdkScrollable>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="warn">Scroll down to accept terms and conditions.</p>
        <markdown [src]="pathDocument"></markdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-checkbox
          [(ngModel)]="acceptTerms"
          [disabled]="completeRead">I have read and agree the Terms and Conditions.</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button
          type="button"
          [ngClass]="{'disable': !acceptTerms}"
          [disabled]="!acceptTerms"
          (click)="onSaveConfirmTerms()"
          class="btn lg primary">Confirm</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
