import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Interceptors
import { TokenInterceptor } from './token.interceptor';

// Pipes
import { SanitizerPipe } from './pipes/sanitazer.pipe';
import { OrderByPipe } from 'app/shared/pipes/order-by.pipe';
import { FilterPipe } from 'app/shared/pipes/filter.pipe';

// Components
import { TrackingModalComponent } from './../dashboard/trackingModal/tracking-modal.component';
import { ReorderModalComponent } from './../reorder-modal/reorder-modal.component';
import { LedgeModalComponent } from './../ledgeModal/ledge-modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ModalComponent } from '../modal/modal.component';
import { OrderShoppingCartSimilarModalComponent } from '../order-shopping-cart-similar-modal/order-shopping-cart-similar-modal.component'
import { PalletizeModalComponent } from 'app/shopping-cart/palletize-modal/palletize-modal.component';
import { TermConditionsModalComponent } from 'app/term-conditions-modal/term-conditions-modal.component';


// Angular Material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule, MatNativeDateModule, MatSelectModule } from '@angular/material';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';

// Ngx Components
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    ScrollDispatchModule,
    MatDialogModule,
    MarkdownModule.forChild(),
    ModalModule.forRoot()
  ],
  declarations: [
    SpinnerComponent,
    ModalComponent,
    SanitizerPipe,
    ReorderModalComponent,
    TrackingModalComponent,
    LedgeModalComponent,
    OrderByPipe,
    FilterPipe,
    OrderShoppingCartSimilarModalComponent,
    TermConditionsModalComponent,
    PalletizeModalComponent
  ],
  exports: [
    SpinnerComponent,
    ModalComponent,
    SanitizerPipe,
    ReorderModalComponent,
    TrackingModalComponent,
    LedgeModalComponent,
    OrderByPipe,
    FilterPipe,
    OrderShoppingCartSimilarModalComponent,
    TermConditionsModalComponent,
    PalletizeModalComponent
  ],
  entryComponents: [
    OrderShoppingCartSimilarModalComponent,
    TermConditionsModalComponent,
    PalletizeModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
