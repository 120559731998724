var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
// Angular Material
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Services
import { ApartmentComplexInformationService } from 'app/shared/services/aparment-complex-information.service';
import { FedexService } from 'app/shared/services/fedex.service';
import { ServerStatusService } from 'app/shared/services/server-status.service';
import { ShoppingCartService } from 'app/shared/services/shopping-cart.service';
// Values
import { ShippingAddressPlaceholder, PalletizeContactPlaceholder } from 'app/shared/mock/shipping-address-values';
import { Fedex } from 'app/shared/types/fedex';
import { PalletizeContact, PalletizeContactForm, ShippingAddressForm } from 'app/shared/types/shipping-address';
// Components
import { SpinnerComponent } from 'app/spinner/spinner.component';
// Moment
import moment from 'moment';
// BlockUI
import { BlockUI } from 'ng-block-ui';
var PalletizeModalComponent = /** @class */ (function () {
    function PalletizeModalComponent(formBuilder, shoppingCartService, apartmentComplexInformationService, fedexService, serverStatusService, dialogRef, dialog, 
    // @Inject(MAT_DIALOG_DATA) public data: { shoppingCart: ShoppingCartExtended, palletizeOptions: any[], productionDates: { start: string }[] }
    data) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.shoppingCartService = shoppingCartService;
        this.apartmentComplexInformationService = apartmentComplexInformationService;
        this.fedexService = fedexService;
        this.serverStatusService = serverStatusService;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.palletizeInformation = this.initPalletForm();
        this.isAlreadyPalletized = false;
        this.palletizedAddress = new ShippingAddressForm(null, null, '', 'Leasing Office', null, null, null, '', 'State*', null);
        this.states = [];
        this.valid = false;
        this.shippingAddressPlaceholder = ShippingAddressPlaceholder;
        this.palletizeContactPlaceholder = PalletizeContactPlaceholder;
        this.fedexMatcher = new Fedex(['', ''], '', '', '', '');
        this.date = new FormControl(moment());
        this.missingDates = [];
        this.isInArray = function (array, value) { return array.some(function (item) { return _this.isSameDate(item, moment.utc(value).toDate()); }); };
        this.datesFilter = function (d) {
            if (_this.missingDates.some(function (excludedDate) { return _this.isSameDate(d, excludedDate); })) {
                return false;
            }
            return d.getDay() !== 0 && d.getDay() !== 6;
        };
    }
    PalletizeModalComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var re_email;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.generateMissingDates(this.data.productionDates.map(function (x) {
                            var offsetMinutes = new Date().getTimezoneOffset();
                            var localTime = new Date(x.start);
                            return new Date(localTime.getTime() + offsetMinutes * 60000);
                        }));
                        re_email = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,12}|[0-9]{1,3})(\]?)$/;
                        this.minDeliveryDate = this.calculateMinDeliveryDate();
                        this.palletize_form = this.formBuilder.group({
                            'name': [null],
                            'addressLine1': [null, Validators.required],
                            'addressLine2': [null],
                            'addressLine3': ['Leasing Office'],
                            'zipCode': [null, Validators.required],
                            'city': [null, Validators.required],
                            'state': [null, Validators.required],
                            'phone': [null],
                            'contactPhone': [null],
                            'shippingInstructions': [null],
                            'contactName': [null, Validators.required],
                            'contactEmail': new FormControl('', [Validators.required, Validators.pattern(re_email)]),
                            'authorizationcontactEmail': new FormControl('', [Validators.required, Validators.pattern(re_email)]),
                            'entranceCode': [null],
                            'aditionalInformation': [null],
                            'authorizationContactName': [null, Validators.required],
                            'authorizationContactPhone': [null],
                        });
                        this.optionSelect = new Array(this.data.palletizeOptions.length).fill(false);
                        return [4 /*yield*/, this.isPalletizedOrder()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getStates()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.setPalletizeAddress()];
                    case 3:
                        _a.sent();
                        if (!!this.isAlreadyPalletized) return [3 /*break*/, 4];
                        this.optionSelect[0] = true;
                        this.palletizeInformation.palletizeOptionID = this.data.palletizeOptions[0].palletizeOptionId;
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.getPalletizeInformation()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        setTimeout(function () {
                            _this.validateForm();
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.scrollToField = function (dateField) {
        if (dateField) {
            dateField.scrollIntoView({ behavior: 'instant', block: 'start' });
        }
    };
    PalletizeModalComponent.prototype.initPalletForm = function () {
        return new PalletizeContactForm('', '', '', '', '', '', 0, '', '', '', '', '', '', '', false, false, false, false, 0, '', false, 0, PalletizeContact[2] = [{ id: 0, fullName: "", phoneNumber: "", emailAddres: "", contactTypeId: 1 }, { id: 0, fullName: "", phoneNumber: "", emailAddres: "", contactTypeId: 2 }]);
    };
    PalletizeModalComponent.prototype.closeModal = function () {
        this.dialogRef.close({ state: false });
    };
    PalletizeModalComponent.prototype.calculateMinDeliveryDate = function () {
        var today = new Date();
        var businessDays = 9;
        while (businessDays > 0) {
            today.setDate(today.getDate() + 1);
            if (today.getDay() !== 0 && today.getDay() !== 6) {
                businessDays--;
            }
        }
        return today;
    };
    PalletizeModalComponent.prototype.getMinMaxDates = function (dates) {
        if (!dates || dates.length === 0) {
            return { minDate: null, maxDate: null };
        }
        var minDate = dates[0];
        var maxDate = dates[0];
        for (var _i = 0, dates_1 = dates; _i < dates_1.length; _i++) {
            var date = dates_1[_i];
            if (date < minDate) {
                minDate = date;
            }
            if (date > maxDate) {
                maxDate = date;
            }
        }
        return { minDate: minDate, maxDate: maxDate };
    };
    PalletizeModalComponent.prototype.addMissingDates = function (arr1, arr2) {
        var missingDates = [];
        for (var _i = 0, arr1_1 = arr1; _i < arr1_1.length; _i++) {
            var date = arr1_1[_i];
            if (!this.isInArray(arr2, date.toJSON())) {
                var offsetMinutes = new Date().getTimezoneOffset();
                var localTime = new Date(date);
                missingDates.push(new Date(localTime.getTime() + offsetMinutes * 60000));
            }
        }
        return missingDates;
    };
    PalletizeModalComponent.prototype.generateMissingDates = function (productionDates) {
        var _a = this.getMinMaxDates(productionDates), minDate = _a.minDate, maxDate = _a.maxDate;
        var datesInRange = this.getDatesInRange(minDate, maxDate);
        this.missingDates = this.addMissingDates(datesInRange, productionDates);
    };
    PalletizeModalComponent.prototype.getDatesInRange = function (startDate, endDate) {
        var datesInRange = [];
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            datesInRange.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return datesInRange;
    };
    PalletizeModalComponent.prototype.isSameDate = function (date1, date2) {
        return (date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear());
    };
    PalletizeModalComponent.prototype.editPalletized = function () {
        return __awaiter(this, void 0, void 0, function () {
            var palletId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shoppingCartService.editPalletized(this.palletizeInformation, this.data.shoppingCartGroup.addressId).toPromise()];
                    case 1:
                        palletId = _a.sent();
                        this.palletizeInformation = this.initPalletForm();
                        this.blockUi.stop();
                        this.dialogRef.close({ state: true, palletizeFormId: palletId });
                        return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.savePalletized = function () {
        return __awaiter(this, void 0, void 0, function () {
            var palletId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shoppingCartService.savePalletForm(this.palletizeInformation, this.data.shoppingCartGroup.addressId).toPromise()];
                    case 1:
                        palletId = _a.sent();
                        this.isAlreadyPalletized = palletId == 0 ? false : true;
                        this.palletizeInformation = this.initPalletForm();
                        this.blockUi.stop();
                        this.dialogRef.close({ state: true, palletizeFormId: palletId });
                        return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.validateForm = function () {
        this.valid =
            this.palletize_form.valid
                && this.palletizedAddress.shippingAddressState !== 'State*'
                && this.palletizeInformation.palletizeOptionID > 0
                && this.palletizeInformation.deliveryDate != '';
    };
    PalletizeModalComponent.prototype.setState = function (state) {
        this.palletizedAddress.shippingAddressState = state;
        if (this.states && this.states.length > 0) {
            for (var _i = 0, _a = this.states; _i < _a.length; _i++) {
                var states = _a[_i];
                if (states.name === state) {
                    this.palletizeInformation.shippingAddressStateId = states.id;
                    break;
                }
            }
        }
    };
    PalletizeModalComponent.prototype.setStateName = function (stateId) {
        for (var _i = 0, _a = this.states; _i < _a.length; _i++) {
            var states = _a[_i];
            if (states.id == stateId) {
                this.palletizeInformation.shippingAddressState = states.name;
                break;
            }
        }
    };
    PalletizeModalComponent.prototype.getStates = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.apartmentComplexInformationService.getStates().toPromise()];
                    case 1:
                        _a.states = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.getPalletizeInformation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isAlreadyPalletized) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.shoppingCartService.getPalletizeInformation(this.data.palletizeFormId).toPromise()];
                    case 1:
                        _a.palletizeInformation = _b.sent();
                        this.optionSelect[this.palletizeInformation.palletizeOptionID - 1] = true;
                        this.setStateName(this.palletizeInformation.shippingAddressStateId);
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.choseKitOptions = function (index, palletizeOptionId) {
        this.optionSelect.fill(false);
        this.optionSelect[index] = true;
        this.palletizeInformation.palletizeOptionID = palletizeOptionId;
        this.validateForm();
    };
    PalletizeModalComponent.prototype.setPalletizeAddress = function () {
        return __awaiter(this, void 0, void 0, function () {
            var address, _i, _a, states;
            return __generator(this, function (_b) {
                address = this.data.shoppingCartGroup;
                this.palletizeInformation.shippingAddressName = address.addressName;
                this.palletizeInformation.shippingAddressLine1 = address.addressLine1;
                this.palletizeInformation.shippingAddressCity = address.city;
                this.palletizeInformation.shippingAddressZipCode = address.zipCode;
                this.palletizeInformation.shippingAddressStateId = this.states.find(function (f) { return f.abbreviation === address.state; }).id;
                this.palletizeInformation.shippingAddressLine2 = address.addressLine2;
                for (_i = 0, _a = this.states; _i < _a.length; _i++) {
                    states = _a[_i];
                    if (states.id === this.palletizeInformation.shippingAddressStateId) {
                        this.palletizeInformation.shippingAddressState = states.name;
                        break;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    PalletizeModalComponent.prototype.isPalletizedOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.isAlreadyPalletized = this.data.palletizeFormId !== 0 && this.data.palletizeFormId != null;
                return [2 /*return*/];
            });
        });
    };
    PalletizeModalComponent.prototype.submitForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.modalFedex();
                return [2 /*return*/];
            });
        });
    };
    PalletizeModalComponent.prototype.modalFedex = function () {
        return __awaiter(this, void 0, void 0, function () {
            var originalAddress, _a, err_1, error;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.palletize_form.valid) return [3 /*break*/, 4];
                        originalAddress = {
                            streetLines: [this.palletizeInformation.shippingAddressLine1, this.palletizeInformation.shippingAddressLine2],
                            postalCode: this.palletizeInformation.shippingAddressZipCode,
                            city: this.palletizeInformation.shippingAddressCity,
                            stateOrProvince: this.palletizedAddress.shippingAddressState,
                            countryCode: 'US'
                        };
                        this.match = '';
                        this.badAddressMessage = '';
                        this.dialog.open(this.fedexModal, { height: "530px", maxWidth: "900px", id: "fedex-modal" });
                        this.blockUi.start('Loading matches...');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.fedexService.getAddress(originalAddress).toPromise()];
                    case 2:
                        _a.fedexMatcher = (_b.sent());
                        if (this.fedexMatcher && this.fedexMatcher.warningMessage) {
                            this.badAddressMessage = this.fedexMatcher.warningMessage;
                        }
                        if (this.fedexMatcher.length === 0) {
                            this.fedexSpinner.onSuccess('Not Matches found...');
                            this.blockUi.stop();
                        }
                        else {
                            this.match = this.fedexMatcher.streetLines.length > 1 ?
                                this.fedexMatcher.streetLines[0] + ",\n          " + this.fedexMatcher.streetLines[1] + ",\n          " + this.fedexMatcher.city + ",\n          " + this.fedexMatcher.stateOrProvince + ",\n          " + this.fedexMatcher.postalCode :
                                this.fedexMatcher.streetLines[0] + ",\n          " + this.fedexMatcher.city + ",\n          " + this.fedexMatcher.stateOrProvince + ",\n          " + this.fedexMatcher.postalCode;
                            this.blockUi.stop();
                            this.fedexSpinner.onSuccess('Matches loaded...');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.blockUi.stop();
                        error = this.serverStatusService.status(err_1.status, '');
                        if (!error) {
                            error = err_1.status + " - " + err_1.statusText;
                        }
                        this.fedexSpinner.onFail(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PalletizeModalComponent.prototype.setFedex = function (keep) {
        this.blockUi.start('Saving');
        if (keep) {
            this.dialog.getDialogById('fedex-modal').close();
            this.fedexMatcher = new Fedex(['', ''], '', '', '', '');
            this.match = '';
            this.keepAddress = undefined;
            this.apartmentComplexInformationService.sectionHandler.next(8);
        }
        else {
            this.palletizedAddress.shippingAddressLine1 = this.fedexMatcher.streetLines[0];
            this.palletizedAddress.shippingAddressZipCode = this.fedexMatcher.postalCode;
            this.palletizedAddress.shippingAddressCity = this.fedexMatcher.city;
            for (var _i = 0, _a = this.states; _i < _a.length; _i++) {
                var state = _a[_i];
                if (this.fedexMatcher.stateOrProvince.toUpperCase() === state.abbreviation.toUpperCase()) {
                    this.palletizedAddress.shippingAddressState = String(state.id);
                    break;
                }
            }
            this.fedexMatcher = new Fedex(['', ''], '', '', '', '');
            this.match = '';
            this.keepAddress = undefined;
            this.dialog.getDialogById('fedex-modal').close();
        }
        if (this.palletizeInformation.palletFormId > 0 && this.data.shoppingCartGroup.hasSomeWithPalletized)
            this.editPalletized();
        else
            this.savePalletized();
    };
    PalletizeModalComponent.prototype.phone = function (phone, model, event) {
        if (event.key !== 'Backspace') {
            if (phone.length === 3 || phone.length === 7) {
                if (event.key !== '-') {
                    if (model == "1") {
                        this.palletizeInformation.shippingAddressPhone = phone += '-';
                    }
                    else if (model == "2") {
                        this.palletizeInformation.contactList[0].phoneNumber = phone += '-';
                    }
                    else {
                        this.palletizeInformation.contactList[1].phoneNumber = phone += '-';
                    }
                }
            }
        }
    };
    __decorate([
        BlockUI(),
        __metadata("design:type", Object)
    ], PalletizeModalComponent.prototype, "blockUi", void 0);
    return PalletizeModalComponent;
}());
export { PalletizeModalComponent };
