/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-custom-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./app-custom-header.component";
import * as i4 from "@angular/router";
var styles_AppCustomHeaderComponent = [i0.styles];
var RenderType_AppCustomHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppCustomHeaderComponent, data: {} });
export { RenderType_AppCustomHeaderComponent as RenderType_AppCustomHeaderComponent };
function View_AppCustomHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col col-6 align-self-center right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sign-in"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Sign In "]))], null, null); }
export function View_AppCustomHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "nav", [["class", "navbar sticky-top navbar-light bg-light header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col col-4 col-lg-5 col-md-4 align-self-center d-inline-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "c\u00A1nch"], ["class", "navbar-brand logo"], ["src", "assets/icons/logo.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openNewTab() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppCustomHeaderComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = null; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_AppCustomHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-header", [], null, null, null, View_AppCustomHeaderComponent_0, RenderType_AppCustomHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppCustomHeaderComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppCustomHeaderComponentNgFactory = i1.ɵccf("app-custom-header", i3.AppCustomHeaderComponent, View_AppCustomHeaderComponent_Host_0, {}, {}, []);
export { AppCustomHeaderComponentNgFactory as AppCustomHeaderComponentNgFactory };
