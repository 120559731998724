import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TwilioService = /** @class */ (function () {
    function TwilioService(http) {
        this.http = http;
        this.apiUrl = '';
    }
    TwilioService.prototype.sendSMS = function (phoneNumber, message) {
        return this.http.post(this.apiUrl, { phoneNumber: phoneNumber, message: message });
    };
    TwilioService.ngInjectableDef = i0.defineInjectable({ factory: function TwilioService_Factory() { return new TwilioService(i0.inject(i1.HttpClient)); }, token: TwilioService, providedIn: "root" });
    return TwilioService;
}());
export { TwilioService };
