import { AuthGuard, NoAuthGuard } from './guards/auth.guard';

import { ApartmentComplexInformationService } from './shared/services/aparment-complex-information.service';
import { ApartmentComplexService } from './shared/services/apartment-complex.service';
import { ApartmentService } from './shared/services/apartment.service';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CabinetSkinsService } from './shared/services/cabinet-skins.service';
import { CapitalizeService } from './shared/services/capitalize.service';
import { ColorService } from './shared/services/color.service';
import { CreateAccountService } from './shared/services/create-account.service';
import { CustomItemsService } from './shared/services/custom-items.service';
import { DateParserService } from './shared/services/date-parser.service';
import { FedexService } from './shared/services/fedex.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HingesService } from './shared/services/hinges.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LandingApartmentComplexService } from './shared/services/landing-apartment-complex.service';
import { LoginModule } from './login/login.module';
import { LoginService } from './shared/services/login.service';
import { NewOrderService } from './shared/services/new-order.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PullsService } from './shared/services/pulls.service';
import { SearchService } from './shared/services/search.service';
import { ServerStatusService } from './shared/services/server-status.service';
import { ShoppingCartService } from './shared/services/shopping-cart.service';
import { SortService } from './shared/services/sort.service';
import { StyleService } from './shared/services/style.service';
import { ToastrModule } from 'ngx-toastr';
import { routing } from './app.routing';
import { KitOptionsService } from './shared/services/kitOptions.service';
import { SignalrService } from './shared/services/signalr.service';
import { BlockUIModule } from 'ng-block-ui';
import { AddToCartService } from './shared/services/add-to-cart.service';
import { OrderReviewService } from './shared/services/order-review.service';
import { SchedulingService } from "./shared/services/scheduling.service";
import { AccountService } from "./shared/services/account.service";
import { MarkdownModule } from "ngx-markdown";
import { DoorConstructionService } from "./shared/services/door-construction.service"
import { MatIconModule } from '@angular/material/icon';
import { ModalModule } from 'ngx-bootstrap';
import { ModalFedexComponent } from './modal-fedex/modal-fedex.component';
import { ScreenBehaviorDirective } from './shared/directives/screen-behavior.directive';
import { SharedModule } from './shared/shared.module';
import { ExpeditedService } from './shared/services/expedited-capacity.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { AppCustomHeaderComponent } from './app-custom-header/app-custom-header.component';
import { TwilioService } from './shared/services/twilio.service';
import { MatDialogModule } from '@angular/material/dialog';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TwilioModule } from './shared/twilio/twilio.module';
import { QrCodeService } from './shared/services/qr-code.service';
import { LoginFooterComponent } from './login-footer/login-footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModalFedexComponent,
    ScreenBehaviorDirective,
    AppCustomHeaderComponent,
    LoginFooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    LoginModule,
    routing,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BlockUIModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    MarkdownModule.forRoot({loader: HttpClient}),
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    SharedModule,
    FormsModule,
    MatDialogModule,
    TwilioModule,
    ModalModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    NoAuthGuard,
    LoginService,
    ApartmentComplexService,
    ServerStatusService,
    SearchService,
    SortService,
    DateParserService,
    LandingApartmentComplexService,
    ApartmentComplexInformationService,
    ColorService,
    StyleService,
    PullsService,
    HingesService,
    FedexService,
    CapitalizeService,
    CustomItemsService,
    CabinetSkinsService,
    NewOrderService,
    ApartmentService,
    CreateAccountService,
    ShoppingCartService,
    KitOptionsService,
    OrderReviewService,
    AddToCartService,
    SignalrService,
    SchedulingService,
    DoorConstructionService,
    AccountService,
    ExpeditedService,
    TwilioService,
    QrCodeService,
    TwilioService
  ],
  entryComponents: [
    ModalFedexComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
