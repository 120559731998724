import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QrCodeService = /** @class */ (function () {
    function QrCodeService(http) {
        this.http = http;
    }
    QrCodeService.prototype.shortenUrl = function (longUrl) {
        return this.http.get(environment.apiQwikkitOrdering + "api/qrcode/shorten?longUrl=" + encodeURIComponent(longUrl));
    };
    QrCodeService.prototype.getQrCodeUrl = function (shortUrl) {
        var shortId = shortUrl.replace('https://cutt.ly/', '');
        return "https://cutt.ly/api/qr-code/api.php?short=" + shortId;
    };
    QrCodeService.prototype.generateQrCode = function (apartmentComplexID, apartmentId) {
        return this.http.get(environment.apiQwikkitOrdering + "api/qrcode/generate?apartmentComplexId=" + apartmentComplexID + "&apartmentId=" + apartmentId);
    };
    QrCodeService.prototype.resolveShortUrl = function (shortUrl) {
        return this.http.get(environment.apiQwikkitOrdering + "api/qrcode/resolve?shortUrl=" + encodeURIComponent(shortUrl));
    };
    QrCodeService.ngInjectableDef = i0.defineInjectable({ factory: function QrCodeService_Factory() { return new QrCodeService(i0.inject(i1.HttpClient)); }, token: QrCodeService, providedIn: "root" });
    return QrCodeService;
}());
export { QrCodeService };
